<template>
  <!--<div class="post-view w-100 container">
    <div class="row justify-content-start pt-4 mt-4">
      <div class="col-4">
        <img :src="'/img/tomorrow/'+post.data.pic" @error="$event.target.src='/img/default.png'"  class="w-100 post-image" :alt="post.data.name">
      </div>
      <div class="col-1">

      </div>
      <div class="col-7">
        <div class="row justify-content-start align-items-start">
          <div class="col-4">
            <h4 class="post-title b-plast">{{post.data.name}}</h4>
          </div>
          <div class="col-12">
            <p class="post-content">
                {{ post.data.txt }}‬
            </p>
            <div class="d-flex justify-content-end">
              <span class="whatch-icon-span">{{ post.data.see }}<fa icon="eye" class="whatch-icon"/></span>
              <span class="whatch-icon-span">{{ post.comments }}<fa icon="comments" class="whatch-icon"/></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <div class="post-view w-100 bg-white">
    <div class="px-1 w-100 container bg-white pb-5 pt-3">
      <div class="t-title">
        <h3 class="t-title h3">بخش اول</h3>
        <h5 class="t-title h5">بتن ریزی</h5>
      </div>
      <div class="t-content row">
        <div class="col-12">
          <div class="text-justify flex">
            <span class="image-left mr-4 mt-4"><img src="/img/tomorrow/beton/beton-amade.jpg" class="" alt=""></span>
            <h6 class="t-title-bold border-plast bb">بتن آماده چیست؟</h6>
            <p class="f-16 mx-2">ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﯾﮑﯽ ﺍﺯ ﺭﺍﻫﮑﺎﺭﻫﺎﯾﯽ ﺍﺳﺖ ﮐﻪ ﺩﺭ ﺻﻨﻌﺖ ﺳﺎﺧﺖ ﻭ ﺳﺎﺯ ﺑﺮﺍﯼ ﻣﻘﺎﺑﻠﻪ ﺑﺎ ﺷﻠﻮﻏﯽ ﻭ ﺁﻟﻮﺩﮔﯽ ﺩﺭ
              ﮐﺴﺐ ﻭ ﮐﺎﺭ ﺍﺳﺘﻔﺎﺩﻩ ﻣﯽ ﮔﺮﺩﺩ. ﺑﺎ ﺭﺷﺪ ﺭﻭﺯﺍﻓﺰﻭﻥ ﻓﻀﺎﯼ ﺷﻬﺮﯼ ﻭ ﻫﻤﭽﻨﯿﻦ ﻣﺤﺪﻭﺩ ﺷﺪﻥ ﻓﻀﺎﯼ ﮐﺎﺭﮔﺎﻫﯽ، ﺑﺴﯿﺎﺭﯼ ﺍﺯ ﺳﺎﺯﻧﺪﮔﺎﻥ ﻭ
              ﭘﯿﻤﺎﻧﮑﺎﺭﺍﻥ ﺑﻪ ﺍﺳﺘﻔﺎﺩﻩ ﺍﺯ ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﺭﻭﯼ ﺁﻭﺭﺩﻩﺍﻧﺪ ﻭ ﺍﯾﻦ ﻧﻮﻉ ﺑﺘﻦ ﺑﻪﻋﻨﻮﺍﻥ ﯾﮏ ﻣﺤﺼﻮﻝ ﻣﻨﺎﺳﺐ ﺷﻨﺎﺧﺘﻪ ﻣﯽﺷﻮﺩ.ﺑﺘﻦ
              ﺁﻣﺎﺩﻩ ﯾﺎ RMC (Ready-mix concrete) ﯾﮏ ﻧﻮﻉ ﺧﺎﺻﯽ ﺍﺯ ﺑﺘﻦ ﺍﺳﺖ ﮐﻪ ﺩﺭ ﯾﮏ ﮐﺎﺭﺧﺎﻧﻪ ﺳﯿﻤﺎﻥ ﯾﺎ ﺩﺭ ﯾﮏ ﻓﻀﺎﯼ ﺑﭽﯿﻨﮓ ﻭ
              ﮐﺎﺭﮔﺎﻫﯽ ﻣﺨﺼﻮﺹ ﺍﯾﻦ ﮐﺎﺭ ﺗﻮﻟﯿﺪ ﻣﯽﺷﻮﺩ ﻭ ﺳﭙﺲ ﺗﻮﺳﻂ ﮐﺎﻣﯿﻮﻥ ﻫﺎ ﯾﺎ ﻣﯿﮑﺴﺮ ﻫﺎ ﺑﻪ ﻣﺤﻞ ﮐﺎﺭ ﻓﺮﺳﺘﺎﺩﻩ ﻣﯽﺷﻮﺩ. ﺍﯾﻦ ﻓﺮﺁﯾﻨﺪ
              ﺑﺎﻋﺚ ﻣﯽ ﺷﻮﺩ ﺗﺎ ﯾﮏ ﻣﺨﻠﻮﻁ ﺩﻗﯿﻖ ﻭ ﻋﺎﻟﯽ ﻣﺨﺼﻮﺹ ﻣﺤﻞ ﺳﺎﺧﺖ ﻭ ﺳﺎﺯ ﺗﻮﻟﯿﺪ ﺷﻮﺩ. ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﺩﺭ ﻫﺮ ﻣﺤﻠﯽ ﮐﻪ ﺍﺳﺘﻔﺎﺩﻩ ﻣﯿﺸﻮﺩ
              ﺩﺭ ﺑﺮﺍﺑﺮ ﻋﻮﺍﻣﻠﯽ ﭼﻮﻥ ﺳﯿﻞ، ﺁﺗﺶ، ﺑﺎﺩ ﻭ ﺗﮕﺮﮒ ﻭ… ﺑﺴﯿﺎﺭ ﻣﻘﺎﻭﻡ ﻣﯿﺒﺎﺷﺪ. ﺑﻨﺎﺑﺮﺍﯾﻦ ﺑﺴﺘﻪ ﺑﻪ ﺑﺰﺭﮔﯽ ﺫﺭﺍﺕ ﺷﻦ ﻭ ﻣﺎﺳﻪ ﯼ ﺑﻪ
              ﮐﺎﺭ ﺭﻓﺘﻪ ﺩﺭ ﺑﺘﻦ ﻭ ﻣﯿﺰﺍﻥ ﺁﺏ ﻭ ﺳﯿﻤﺎﻧﯽ ﮐﻪ ﺍﺳﺘﻔﺎﺩﻩ ﺷﺪﻩ ﺩﺭ ﺁﻥ ﻣﯿﺘﻮﺍﻧﺪ ﻣﯿﺰﺍﻥ ﻣﻘﺎﻭﻣﺖ ﻣﺨﺘﻠﻔﯽ ﺩﺍﺷﺘﻪ ﺑﺎﺷﺪ ﻭ ﺑﺴﺘﻪ ﺑﻪ
              ﻧﻮﻉ ﮐﺎﺭﺑﺮﺩ ﺁﻥ ﻣﻮﺭﺩ ﺍﺳﺘﻔﺎﺩﻩ ﻗﺮﺍﺭ ﮔﯿﺮﺩ. ﺷﺮﮐﺖ ﺁﺫﺭﭘﮋﻭﻫﺶ ﺑﺎ ﺗﻮﺟﻪ ﺑﻪ ﺑﻬﺮﻩ ﮔﯿﺮﯼ ﺍﺯ ﻧﯿﺮﻭﻫﺎﯼ ﻣﺘﺨﺼﺺ ﺧﻮﺩ ﺁﻣﺎﺩﻩ ﺗﻬﯿﻪ
              ﺍﻧﻮﺍﻉ ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﻭ ﺍﺭﺳﺎﻝ ﺑﻪ ﺗﻤﺎﻣﯽ ﻣﻨﺎﻃﻖ ﺗﻬﺮﺍﻥ ﻭ ﮐﺮﺝ ﻣﯽ ﺑﺎﺷﺪ.</p>
          </div>
        </div>
        <div class="col-12 d-block">
          <div class="flex d-block">
            <span class="image-right-rotate"><img src="/img/tomorrow/beton/batching.jpg" class="w-100" alt=""></span>
            <span class="rectangle mb-3"></span>
            <h6 class="t-title-bold pb-4 pt-5 mt-5 border-plast bb">نیم نگاهی به فرآیند تولید بتن آماده</h6>
            <span class="image-left-rotate mt-4"><img src="/img/tomorrow/beton/batching-plant.jpg"
                class="w-100 height-low" alt=""></span>
            <p class="text-justify">ﻣﻮﺍﺩ ﺑﺘﻦ ﺍﻣﺎﺩﻩ ﺷﺎﻣﻞ ﻣﻘﺎﺩﯾﺮﯼ ﻣﺸﺨﺼﯽ ﺳﯿﻤﺎﻥ ، ﺁﺏ، ﺳﻨﮓ ﺁﻫﮏ ﻭ ﺍﻓﺰﻭﺩﻧﯽ ﻫﺎﯼ ﺑﺘﻦ ﺍﺳﺖ ﻭ ﻣﻘﺪﺍﺭ
              ﻣﺼﺮﻑ ﻫﺮ ﮐﺪﺍﻡ ﺍﺯ ﺍﯾﻦ ﻣﻮﺍﺩ ﺑﺴﺘﮕﯽ ﺑﻪ ﻧﯿﺎﺯ ﻣﺸﺘﺮﯼ ﻭ ﺟﺎﯾﯽ ﺧﻮﺍﻫﺪ ﺩﺍﺷﺖ ﮐﻪ ﻗﺮﺍﺭ ﺍﺳﺖ ﺍﺯ ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﺍﺳﺘﻔﺎﺩﻩ ﺷﻮﺩ. ﺩﺭ
              ﻭﺍﻗﻊ ﻃﺮﺯ ﺗﻬﯿﻪ ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﺭﺍ ﻣﺸﺘﺮﯼ ﻭ ﻧﯿﺎﺯ ﺍﻭ ﻣﺸﺨﺺ ﻣﯿﮑﻨﺪ.ﺩﺭ ﮐﺎﺭﺧﺎﻧﻪﻫﺎﯼ ﺍﺗﻮﻣﺎﺗﯿﮏ ﺍﯾﻦ ﺑﺘﻦ ﻣﻮﺍﺭﺩﯼ ﻣﺎﻧﻨﺪ ﻭﺯﻥ ﺑﺘﻦ،
              ﻧﺴﺒﺖ ﺁﺏ ﺑﻪ ﺳﯿﻤﺎﻥ، ﺩﻭﺯ ﻣﺨﻠﻮﻁ، ﺭﻃﻮﺑﺖ، ﻭ ﻣﯿﺰﺍﻥ ﺩﻗﺖ ﺑﺮﺍﯼ ﺗﻮﻟﯿﺪ ﺑﺘﻦ ﺑﺎ ﮐﯿﻔﯿﺖ ﻣﻮﺭﺩ ﻧﻈﺎﺭﺕ ﻗﺮﺍﺭ ﻣﯽﮔﯿﺮﺩ. ﺗﻤﺎﻡ ﻣﻮﺍﺩ
              ﺗﺸﮑﯿﻞﺩﻫﻨﺪﻩ ﻣﻮﺭﺩ ﺍﺳﺘﻔﺎﺩﻩ ﺑﺮﺍﯼ ﺁﻣﺎﺩﻩﺳﺎﺯﯼ ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﺑﻪ ﻃﻮﺭ ﮐﺎﻣﻞ ﺍﺯ ﻟﺤﺎﻅ ﮐﯿﻔﯿﺖ ﻭ ﺧﻮﺍﺹ ﻓﯿﺰﯾﮑﯽ ﺁﻧﻬﺎ ﺩﺭ ﯾﮏ
              ﺁﺯﻣﺎﯾﺸﮕﺎﻩ ﺑﻪ ﺧﻮﺑﯽ ﻣﺠﻬﺰ ﺩﺭ ﮐﺎﺭﺧﺎﻧﻪ ﺑﺮﺍﯼ ﺍﻧﻄﺒﺎﻕ ﺑﺎ ﮐﺪﻫﺎﯼ ﺍﺳﺘﺎﻧﺪﺍﺭﺩ ﺑﯿﻦﺍﻟﻤﻠﻠﯽ ﻣﻮﺭﺩ ﺁﺯﻣﺎﯾﺶ ﻗﺮﺍﺭ ﻣﯽﮔﯿﺮﻧﺪ. ﺩﺭ
              ﺍﯾﻦ ﺁﺯﻣﺎﯾﺸﮕﺎﻩﻫﺎ ﻣﯿﺰﺍﻥ ﺭﻃﻮﺑﺖ ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﻧﯿﺰ ﻣﻮﺭﺩ ﺑﺮﺭﺳﯽ ﻗﺮﺍﺭ ﻣﯽﮔﯿﺮﺩ، ﮐﻪ ﺑﻪ ﻣﻌﻨﯽ ﮐﻨﺘﺮﻝ ﻧﺴﺒﺖ ﺁﺏ ﺑﻪ ﺑﺘﻦ ﺁﻣﺎﺩﻩ
              ﺍﺳﺖ. ﺩﺭﻧﻬﺎﯾﺖ ﭘﺲ ﺍﺯ ﮐﻨﺘﺮﻝ ﮐﯿﻔﯽ ﻣﻮﺍﺩ ﺍﻭﻟﯿﻪ، ﺑﻪ ﻗﺴﻤﺖ ﻭﺭﻭﺩﯼ ﮐﺎﺭﮔﺎﻩ ﮐﻪ ﺩﺭ ﻭﺍﻗﻊ ﺍﯾﺴﺘﮕﺎﻩ ﻣﺮﮐﺰﯼ ﺑﺘﻦ ﯾﺎ ﺑﭽﯿﻨﮓ ﮔﻔﺘﻪ
              ﻣﯽ ﺷﻮﺩ ﺭﯾﺨﺘﻪ ﻣﯽﺷﻮﻧﺪ ﻭ ﭘﺲ ﺍﺯ ﮔﺬﺷﺖ ﺯﻣﺎﻥ ﻣﺸﺨﺺ ﺑﺘﻦ ﺑﻪ ﺗﺮﺍﮎ ﻣﯿﮑﺴﺮ ﺭﯾﺨﺘﻪ ﺷﺪﻩ ﻭ ﺑﻪ ﻣﺤﻞ ﺳﺎﺧﺖ ﻭ ﺳﺎﺯ ﺍﻧﺘﻘﺎﻝ ﺩﺍﺩﻩ
              ﻣﯽﺷﻮﺩ. ﺩﺭ ﺗﺼﺎﻭﯾﺮ ﺯﯾﺮ ﺩﯾﮓ ﻣﯿﮑﺴﺮ ﺑﭽﯿﻨﮓ ﻭ ﺳﭙﺲ ﺍﯾﺴﺘﮕﺎﻩ ﻣﺮﮐﺰﯼ ﺑﺘﻦ ﻧﺸﺎﻥ ﺩﺍﺩﻩ ﺷﺪﻩ ﺍﺳﺖ.</p>
          </div>
        </div>
        <div class="col-12">
          <div class="flex">
            <h6 class="t-title-bold pb-4 mt-3 border-plast bb">انواع موارد کاربرد بتن آماده</h6>
            <span class="image-left mr-4"><img src="/img/tomorrow/beton/betoni.jpg" class="" alt=""></span>
            <p class="text-justify">
              ﯾﮑﯽ ﺍﺯ ﻣﻬﻤﺘﺮﯾﻦ ﻭ ﺭﺍﯾﺞ ﺗﺮﯾﻦ ﻣﻮﺍﺭﺩ ﮐﺎﺭﺑﺮﺩ ﺑﺘﻦ ﺁﻣﺎﺩﻩ ﺩﺭ ﺳﺎﺧﺖ ﺑﺘﻦ ﻣﺴﻠﺢ ﺩﺭ
              ﭘﯽ ﺳﺎﺧﺘﻤﺎﻥ ﻫﺎ ﯾﺎ ﺩﺭ ﺳﺎﺧﺘﻤﺎﻥ ﻫﺎﯼ ﺍﺳﮑﻠﺖ ﺑﺘﻨﯽ ﻣﯿﺒﺎﺷﺪ. ﯾﮑﯽ ﺩﯾﮕﺮ ﺍﺯ ﮐﺎﺭﺑﺮﺩ ﻫﺎﯼ ﺁﻥ ﺩﺭ ﺳﺎﺧﺖ ﺭﺍﻩ ﺑﺎ ﺭﻭﺳﺎﺯﯼ ﺑﺘﻨﯽ ﻣﯽ
              ﺑﺎﺷﺪ. ﻫﻤﭽﻨﯿﻦ ﺑﻪ ﺩﻟﯿﻞ ﻣﻘﺎﻭﻣﺖ ﺑﺎﻻ ﻣﯽﺗﻮﺍﻧﻨﺪ ﺩﺭ ﻓﻀﺎﻫﺎﯼ ﺑﺎﺯ ﻭ ﭘﺮ ﺗﺮﺍﻓﯿﮏ ﻧﯿﺰ ﺑﻪ ﮐﺎﺭ ﺑﺮﺩﻩ ﺷﻮﻧﺪ. ﮐﺎﺭﺑﺮﺩ ﺩﯾﮕﺮ ﺑﺘﻦ
              ﺁﻣﺎﺩﻩ، ﺍﺳﺘﻔﺎﺩﻩ ﺍﺯﺍﯾﻦ ﻧﻮﻉ ﺑﺘﻦ ﺑﻪ ﻋﻨﻮﺍﻥ ﮐﻒ ﭘﻮﺵ ﺑﺘﻨﯽ ﺍﺳﺖ ﺑﻪ ﺍﯾﻦ ﺩﻟﯿﻞ ﮐﻪ ﺍﯾﻦ ﻧﻮﻉ ﮐﻒ ﭘﻮﺵ ﻫﺎ ﻇﺮﻓﯿﺖ ﮔﺮﻣﺎﯾﯽ ﺑﺎﻻﯾﯽ
              ﺩﺍﺭﻧﺪ، ﻣﻘﺎﻭﻡ ﺗﺮ ﺑﻮﺩﻩ ﻭ ﺗﻨﻮﻉ ﺭﻧﮓ ﻣﺘﻨﺎﺳﺒﯽ ﺭﺍ ﻧﯿﺰ ﺩﺍﺭﺍ ﻣﯽ ﺑﺎﺷﻨﺪ.
              ﺍﺯ ﺟﻤﻠﻪ ﮐﺎﺭﺑﺮﺩ ﻫﺎﯼ ﺩﯾﮕﺮ ﻣﯽ ﺗﻮﺍﻥ ﺑﻪ ﻣﻮﺍﺭﺩ ﺯﯾﺮ ﺍﺷﺎﺭﻩ ﮐﺮﺩ:</p>
              <ul>
                <li>ﺑﺮﺍﯼ ﻓﻮﻧﺪﺍﺳﯿﻮﻥ ﻫﺎﯼ ﻭﯾﮋﻩ ﻣﺜﻞ ﺩﯾﻮﺍﺭ ﻫﺎ، ﺷﻤﻊ ﻫﺎ، ﺩﯾﻮﺍﺭ ﻫﺎﯼ ﭘﺸﺘﯿﺒﺎﻧﯽ</li>
                <li>ﺩﺭ ﺍﺟﺰﺍﯼ ﭘﯿﺶ ﺳﺎﺧﺘﻪ ﺳﺎﺧﺘﻤﺎﻥ ﻣﺜﻞ ﭘﻠﻪ ﭘﯿﺶ ﺳﺎﺧﺘﻪ</li>
              </ul>
              <span class="image-right mr-4"><img src="/img/tomorrow/beton/rah-pele.jpg" class="" alt=""></span>
              <span class="rectangle2"></span>
          </div>
        </div>
        <div class="col-12">
          <div class="flex">
            <h6 class="t-title-bold pb-4 mt-3 border-plast bb">انواع موارد کاربرد بتن آماده</h6>
            <p class="text-justify f-15">
              بتن های آماده در انواع مختلفی تولید میشوند که در زیر به تک تک آنها اشاره میکنیم
            </p>
          </div>
          <div class="flex w-100">
            <span class="image-right-3"><img src="/img/tomorrow/beton/1.jpg" class="w-100" alt=""></span>
            <span class="image-center-3"><img src="/img/tomorrow/beton/2-2.jpg"
                class="w-100" alt=""></span>
            <span class="image-left-3"><img src="/img/tomorrow/beton/3-3.jpg"
                class="w-100" alt=""></span>
            <span class="image-right-3-2 mt-4"><img src="/img/tomorrow/beton/4.jpg" class="w-100" alt=""></span>
            <span class="image-center-3-2 mt-4"><img src="/img/tomorrow/beton/5-5.jpg"
                class="w-100" alt=""></span>
            <span class="image-left-3-2 mt-4"><img src="/img/tomorrow/beton/6-6.jpg"
                class="w-100" alt=""></span>
          </div>
        </div>
        <div class="col-12">
          <div class="flex">
            <h6 class="t-title-bold pb-4 mt-3 border-plast bb left">انواع موارد کاربرد بتن آماده</h6>
            <span class="image-right"><img src="/img/tomorrow/beton/betoni.jpg" class="" alt=""></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    library
  } from '@fortawesome/fontawesome-svg-core'
  import {
    faEye
  } from '@fortawesome/free-solid-svg-icons'
  import {
    faComments
  } from '@fortawesome/free-solid-svg-icons'
  library.add(faEye, faComments);
  export default {
    name: 'PostView',
    props: {
      post: Object,
    }
  }
</script>

<style lang="css">
  @import url("../assets/tomorrow.css");

  .post-title {
    color: #fff;
    font-size: 23px;
    border-bottom: 2px solid;
    padding: 4px;
    padding-bottom: 10px;
    border-image-source: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
    border-image-slice: 1;

  }

  .border-red {
    border: 1px solid red;
  }
  .left{
    justify-self: flex-end;
    align-self:center;
    float: left;
  }

  .post-content {
    color: #fff;
    font-size: 20px;
    line-height: 3rem;
    text-align: justify;
    max-width: 80%;
  }

  .t-title-bold {
    max-width: 39%;
    font-size: 23px;
    color: black;
  }

  .txt {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
    -webkit-shape-outside: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
    shape-outside: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  }

  .whatch-icon-span {
    font-size: 20px;
  }

  .whatch-icon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .bb {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid;
  }

  .post-view {
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  }

  p {
    font-size: 22px;
  }

  .f-15 {
    font-size: 15px;
  }

  .f-16 {
    font-size: 16px;
  }

  .f-17 {
    font-size: 17px;
  }

  .f-18 {
    font-size: 18px;
  }

  .w-120 {
    widows: 120% !important;
  }
</style>